<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">ATA Summary Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex mb-4">
              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Module Name</label>
                <div style="width: 250px">
                  <v-select
                    label="name"
                    v-model="module"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id !== module.id"
                    :loading="!modules.length"
                    @option:selected="FilterData(module, employee)"
                  >
                  </v-select>
                </div>
              </div>
              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Assessor Name</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="employee"
                    :options="employees"
                    :clearable="false"
                    :selectable="(options) => options.id != employee.id"
                    :loading="!employees.length"
                    @option:selected="FilterData(module, employee)"
                  >
                  </v-select>
                </div>
              </div>
              <div @click="refreshData" class="icon-css pt-4 mt-2">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="datatableATA"
                v-if="!loading"
                style="width: 1900px"
              >
                <thead v-if="!loading">
                  <tr>
                    <th style="width: 100px">No.</th>
                    <th style="width: 300px">Module Name</th>
                    <th style="width: 300px">Assessor Name</th>
                    <th style="width: 300px">Trainee Name</th>
                    <th style="width: 300px">Training Date</th>
                    <th style="width: 300px">Assessment Score</th>
                    <th style="width: 300px">
                      If you choose 60% and below, please write detailed reason.
                    </th>
                    <th style="width: 300px">Suggestion for training team</th>
                    <th style="width: 300px">
                      Next Improved Training Titles for your subordinate
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(data, index) in assessment_result" :key="index">
                    <!-- <template v-if="data.status"> -->
                    <td>{{ ++index }}</td>
                    <td>
                      {{
                        data.assessment_before_one_weeks?.monthly_plan.module
                          ?.name
                      }}
                    </td>
                    <td>
                      {{ data.assessment_before_one_weeks?.assessor?.name }}
                    </td>
                    <td>
                      {{
                        data.assessment_before_one_weeks?.enrolled_user?.name
                      }}
                    </td>
                    <td>
                      {{ data.assessment_before_one_weeks?.training_date }}
                    </td>
                    <td>
                      {{ assessmentScore(data.assessment_result_answer) }}
                    </td>
                    <td>{{ data.above_target_mark_suggestion }}</td>
                    <td>{{ data.team_suggestion }}</td>
                    <td>{{ data.next_improved_training_title }}</td>
                    <!-- </template> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Timeline
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../../components/shared/badgeDanger.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";

export default {
  components: {
    badgeSuccess,
    badgeDanger,
    vSelect,
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      assessment_result: [],
      employees: [],
      employee: "",
      modules: [],
      module: "",
      pdfLoading: false,
      excelLoading: false,
      loading: false,
      title: "ATA Summary Report Lists",
      headers: [
        "No.",
        "Module Name",
        "Assessor Name",
        "Training Date",
        "Assessment Score",
        "If you choose 60% and below, please write detailed reason.",
        "Suggestion for training team",
        "Next Improved Training Titles for your subordinate",
      ],
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllModules() {
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.$Progress.finish();
      });
    },
    async getAssessmentResult() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/after-assessment-result`)
        .then((response) => {
          this.assessment_result = response.data.data.filter(
            (val) => val.status
          );

          console.log(this.assessment_result, "saseintein");
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      this.$nextTick(() => {
        $("#datatableATA").DataTable();
      });
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#datatableATA").DataTable().draw();
    },
    assessmentScore(question_list) {
      let score = 0;
      let length = 0;
      for (let data of question_list) {
        if (data.question_name?.question_type == "radio") {
          length++;
          switch (data.answer) {
            case "Strongly Agree":
              score += 100;
              break;
            case "Agree":
              score += 80;
              break;
            case "Disagree":
              score += 60;
              break;
            case "Strongly Disagree":
              score += 40;
              break;
            default:
              score += 0;
              break;
          }
        }
      }
      const average_score = Math.floor(score / length);
      return average_score + "%";
    },
    FilterData(module, employee) {
      this.clearFilter();
      // alert('reach');
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        const check_employee = employee?.name == data[2] ? true : false;
        const check_module = module?.name == data[1] ? true : false;

        return (check_employee || !employee) && (check_module || !module)
          ? true
          : false;
      });
      $("#datatableATA").DataTable().draw();
    },
    exportPdfClick() {
      const dataArray = $("#datatableATA")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = [];

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });
      exportPdf(this.headers, sheetData, this.title);

      this.pdfLoading = false;
    },

    exportExcelClick() {
      const dataArray = $("#datatableATA")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const sheetData = [];

      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });
      exportExcel(sheetData, this.title);
      this.excelLoading = false;
    },

    refreshData() {
      this.employee = "";
      this.module = "";
      // this.status = "";
      this.clearFilter();
    },
  },
  created() {
    this.clearFilter();
    this.getAssessmentResult();
    this.getAllModules();
    let intervalId;
    if (!this.employees.length) {
      intervalId = setInterval(() => {
        this.employees = this.$store.getters["odoo/getAllEmployees"];
        if (this.employees.length) {
          clearInterval(intervalId);
        }
      }, 1000);
    }
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
